import { template as template_8a56dbca84394089aca74c4c461bd1e4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_8a56dbca84394089aca74c4c461bd1e4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
