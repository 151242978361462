import { template as template_d11d2add5b38425aa36be2a202e9ef0e } from "@ember/template-compiler";
const WelcomeHeader = template_d11d2add5b38425aa36be2a202e9ef0e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
